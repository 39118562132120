<template>
  <div>
    <span class="titleslight--text font-weight-medium">{{titulo}}</span>
    <span v-if="obligatorio" class="titleslight--text font-weight-bold ml-1">*</span>
    <v-autocomplete
      v-model="valor"
      :items="itemsData"
      item-text="DatoMostrar"
      placeholder="Seleccione"
      height="30px"
      return-object
      outlined
      dense
      :error="esError"
      :rules="noVacio"
      :readonly="enable">
    </v-autocomplete>
  </div>
</template>
<script>
export default {
  props:{
    value:{
    },
    titulo:{
      type:String,
      default:''
    },
    obligatorio:{
      type:Boolean,
      default: false
    },
    itemsData: Array,
    enable: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      valor: this.value,
      esError : false,
      noVacio : [v => !!v],
      
    }
  },
  watch: {
    valor(NewValor){
      this.$emit('input',NewValor)
      if(this.esError && this.valor != ''){
        this.esError = false
      }
    },
    value(NewValue){
      this.valor = NewValue
    },
    getoFormError(){
      this.esError = this.getoFormError[this.campo]
    }
  }
}
</script>