<template>
  <div>
    <span class="titleslight--text font-weight-medium">{{titulo}}</span>
    <span v-if="obligatorio" class="titleslight--text font-weight-bold ml-1">*</span>
    <v-textarea
      v-model="valor"
      :error="esError"
      :rules="noVacio"
      :readonly="enable"
      outlined
      dense>
    </v-textarea>
  </div>
</template>
<script>
export default {
  props:{
    value:{
    },
    titulo:{
      type:String,
      default:''
    },
    obligatorio:{
      type:Boolean,
      default: false
    },
    enable: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      valor: this.value,
      esError : false,
      noVacio : [v => !!v],
    }
  },
  watch: {
    valor(NewValor){
      this.$emit('input',NewValor)
      if(this.esError && this.valor != ''){
        this.esError = false
      }

    },
    value(NewValue){
      this.valor = NewValue
    },
  }
}
</script>