<template>
  <div>
    <span class="titleslight--text font-weight-medium">{{titulo}}</span>
    <span v-if="obligatorio" class="titleslight--text font-weight-bold ml-1">*</span>
    <v-radio-group  class="ma-0 pt-1"
      v-model="valor"
      row 
      dense
      :error="esError"
      :rules="noVacio"
      :readonly="enable">
      <v-radio v-for="(oData, i) in itemsData" :key="i" :label="oData.DatoMostrar" :value='`{"ID": ${oData.ID}, "DatoMostrar": "${oData.DatoMostrar}"}`'></v-radio>
    </v-radio-group>
  </div>
</template>
<script>
export default {
  props:{
    value:{
    },
    titulo:{
      type:String,
      default:''
    },
    obligatorio:{
      type:Boolean,
      default: false
    },
    itemsData: Array,
    enable: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      valor: this.value,
      esError : false,
      noVacio : [v => !!v],
    }
  },
  methods: {
    searchobject(ValueSearch) {
      for (var i=0; i < this.itemsData.length; i++) {
        if (this.itemsData[i].ID == ValueSearch) {
          return this.itemsData[i];
        }
      }
    }
  },
  watch: {
    valor(NewValor) {
      this.$emit('input', NewValor)
      if(this.esError && this.valor != '') {
        this.esError = false
      }
    },
    value(NewValue) {
      this.valor = NewValue
    },
  }
}
</script>