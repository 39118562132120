<template>
  <div>
    <v-checkbox
      v-model="select">
    </v-checkbox>
  </div>
</template>
<script>
export default {
  props: {

  },
  data(){
    return {
      
    }
  }
}
</script>