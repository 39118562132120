<template>
  <div>
    <span class="titleslight--text font-weight-medium">{{titulo}}</span>
    <span v-if="obligatorio" class="titleslight--text font-weight-bold ml-1">*</span>
    <v-select
      v-model="value"
      :items="items"
      item-text="texto"
      item-value="id"
      :error="esError"
      :rules="noVacio"
      :placeholder="Mensaje"
      :loading="loadSelect"
      multiple
      chips
      small-chips
      outlined>
    </v-select>
  </div>
</template>
<script>
export default {
  props: {
    value: {
    },
    titulo:{
      type:String,
      default:''
    },
    obligatorio:{
      type:Boolean,
      default: false
    },
  },
  data(){
    return {
      valor: this.value,
      Mensaje: 'Cargando..',
      esError: false,
      noVacio: [v => !!v],
      items: ['foo', 'bar', 'fizz', 'buzz'],
    }
  }
}
</script>